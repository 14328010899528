@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Avatar */
.avatar {
  width: 50%;
  aspect-ratio: 1/1; /* Establece el aspecto cuadrado */
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.18);
  margin: 0 auto;
}
.avatar img {
  width: 100%;
  height: 100%;
  margin: "auto";
  object-fit: cover;
  border-radius: 50%;
}

/* Button Style ToolBar */
.button-sidebar {
  background-color: transparent;
  border: none;
  display: flex;
  width: 100%;
  height: 5vh;
  border-radius: 12px;
}
.button-sidebar img {
  width: 2vh;
  height: 2vh;
  margin: auto 12px;
}
.button-sidebar label {
  margin: auto;
  margin-left: 0;
  font-size: 1em;
  color: white;
  text-align: left;
}
.button-sidebar:hover {
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.407);
}
/* This is a no real hover, is the name of class for the effect selected */
.button-sidebar-hover {
  background-color: rgba(0, 0, 0, 0.407);
  font-weight: 400;
}

/* Style Buttons General */
.button-general {
  margin-right: 20px;
  width: auto;
  /* min-height: 3vw; */
  height: auto;
}
.button-general > button {
  width: 100%;
  /* min-height: 3vw; */
  min-width: 6rem;
  margin-top: 1rem;
  height: auto;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.301);
  /* margin-right: 50px; */
}
.button-general label {
  /* margin: auto; */
  color: white;
  text-align: center;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1em;
}
.button-general:hover {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
}
/* This is a no real hover, is the name of class for the effect selected */
.button-general-hover {
  background-color: rgba(0, 0, 0, 0.407);
  min-width: 6rem;
  font-weight: 400;
  border-radius: 12px;
}
/* Style Buttons Navbar */
.button-navbar {
  display: flex;
}
.button-navbar > button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 26px;
  color: white;

  text-align: center;
}
.button-navbar > button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.button-navbar > button > img {
  width: 2vh;
  height: 2vh;
  margin: auto;
  margin-left: 15px;
  margin-right: 20px;
}
.button-navbar > button > label {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 1rem;
  margin: auto;
}
.button-save-company {
  display: flex;
}
.button-save-company > button {
  width: 100%;
  /* min-height: 4vw; */
  height: 100%;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 26px;
  color: white;

  text-align: center;
}
.button-save-company > button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.button-save-company > button > img {
  width: 2vh;
  height: 2vh;
  margin: auto;
  margin-left: 15px;
  margin-right: 20px;
}
.button-save-company > button > label {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 1rem;
  margin: auto;
}

.button-logout {
  display: flex;
}
.button-logout > button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 26px;
  color: white;

  text-align: center;
}
.button-logout > button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.button-logout > button > img {
  width: 2vh;
  height: 2vh;
  margin: auto;
  margin-left: 15px;
  margin-right: 20px;
}
.button-logout > button > label {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 1rem;
  margin: auto;
}

/* Style Buttons General---Avatar*/
.button-avatar {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin: auto;
}
.button-avatar > button {
  color: white;
  display: flex;
  width: 90%;
  height: 8vh;
  margin-bottom: 1em;
  border: none;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 14px;
}
.button-avatar > button {
  color: white;
  display: flex;
  width: 90%;
  height: 7vh;
  margin-bottom: 1em;
  border: none;
  background-color: rgba(255, 255, 255, 0.18);
  border-radius: 14px;
}
.button-avatar > button > img {
  height: 3vh;
  margin: auto;
  margin-left: 7px;
  margin-right: 0 !important;
}
.button-avatar > button > label {
  margin: auto;
  margin-left: 10px !important;

  /* margin-left: 0; */
  /* padding: auto; */
  /* padding-left: 20; */
  text-align: left;
}

/* Style of Button just Icon */
.button-icon {
  position: static;
  margin: auto;
  margin-left: auto;
}
.button-icon > button {
  margin: 0;
  background-color: transparent;
  border: none;
  margin: auto;
  width: 100%;
  height: 100%;

  /* background-color:rgba(255, 255, 255, 0.427); */
}
.button-icon > button > img {
  width: 7vh;
  height: 7vh;
  margin: auto;
}

/*Style of Button just IconShadow  */
.button-icon .shadow-icon {
  margin: 0;
  background-color: transparent;
  border: none;
  margin: auto;
  width: 7vh;
  height: 7vh;
  background-color: rgba(255, 255, 255, 0.188);
  border-radius: 10px;
}
.button-icon .shadow-icon > img {
  background-color: transparent;
  width: 36.3px;
  height: 7vh;
  margin: auto;
}

/*Style of Button just IconShadowLabel  */

.button-icon-label {
  width: 100%;
  height: 5vh;
}
.button-icon-label > label {
  margin: 0;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 5vh;
  background-color: rgba(255, 255, 255, 0.543);
  border-radius: 10px;
  color: white;
  padding: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 1em;
}

/*Style of Button just IconLow  */

.button-icon > button .img-low {
  width: 33.3px;
  height: 33.3px;
  margin: auto;
}

/* Button SideBar2 */
.button-sidebar2 {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  background-color: transparent;
  border: none;
  display: flex;
  height: 52px;
  border-radius: 12px;
  width: 100%;
}
.button-sidebar2 label {
  margin: auto;
  margin-left: 0;
  font-size: 1em;
  color: white;
}
.button-sidebar2:hover {
  background-color: rgba(0, 0, 0, 0.11);
}

/* Style Select */

.container-select select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 8l5 8 5-8z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0px;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0.3em 2.5em 0.3em 10px;
  padding-left: 10px;
  color: #fff;
  width: 100%;
}

.container-select .my-select {
  min-width: 20px;
  width: 20px;
  height: 10px;
  background: transparent;
  border-left: solid 1px #fff;
  padding: 0.45em 0.5em;
  position: absolute;
}

/* Style SELECTNEW */
.dropdown-container {
  text-align: left;
  border: 1px solid rgb(255, 255, 255);
  position: static;
  border-radius: 10px;
  width: 20vw;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-container .dropdown-input {
  /* padding: 18px; */
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  /* width: 17vw; */
}
.dropdown-selected-value {
  color: #ccc;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-left: 12px;
}
.dropdown-tool {
  margin: auto;
}
.dropdown-tool img {
  width: 23.3px;
  height: 23.3px;
  margin: auto;
}
.dropdown-tool span {
  min-width: 20px;
  width: 50px;
  height: 10px;
  background: transparent;
  border-left: solid 1px #fff;
  padding: 24.3px 0.5em;
  padding-top: 20.2px;
  margin: auto;
}

/* Syle of SVG downn in previewCategories */
.box-icons img {
  width: 23.3px;
  height: 23.3px;
  margin: auto;
}

/* Style of drawer */
.drawer {
  position: fixed;
  top: 0;
  left: -300px; /* Inicialmente oculto fuera de la pantalla */
  width: 300px;
  height: 100%;
  background-color: #f247ff;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
}

.drawer.open {
  left: 0; /* Muestra el Drawer deslizable */
}

.drawer-content {
  height: 100%;
}

/* Estilos de botones de la tabla */
.btn-perso-primary {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
.btn-perso-primary:hover {
  color: #007bff !important;
  background-color: #ffffff95 !important;
  border-color: #007bff !important;
}

.btn-perso-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-perso-secondary:hover {
  color: #6c757d !important;
  background-color: #ffffff95 !important;
  border-color: #6c757d !important;
}

.btn-perso-warning {
  color: #212529 !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.btn-perso-warning:hover {
  color: #ffc107 !important;
  background-color: #ffffff95 !important;
  border-color: #ffc107 !important;
}

.btn-perso-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.btn-perso-danger:hover {
  color: #dc3545 !important;
  background-color: #ffffff95 !important;
  border-color: #dc3545 !important;
}
