.picture {
  aspect-ratio: 1/1;
  width: 35%;
  border-radius: 50%;
}

.generatedPicture {
  position: relative;
  aspect-ratio: 1/1;
  background-color: purple;
  height: 100%;
  width: 35%;
  border-radius: 50%;
  border: 1px solid #fff;
}

.generatedPictureInitials {
  position: absolute;
  left: 37%;
  top: 20%;
}

@media (max-width: 640px) {
  .picture {
    width: 40px;
  }

  .generatedPicture {
    background-color: purple;
    width: 40px;
    height: 100%;
  }

  .generatedPictureInitials {
    position: absolute;
    left: 37%;
    top: 20%;
    font-size: 12px;
  }
}
