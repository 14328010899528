

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  justify-content: center;
  margin: 4%;
 
}

/* Styled Main Panel */
/* .container{
  display: flex;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);

}
.navbarPadre{
 grid-column: 1;
  grid-row: 1;
}
.acordationPadre{
 grid-column: 1 ;
  grid-row:2 / 5;
}
.cardPadre{
 grid-column:2/4;
 grid-row: 1;
} */

.form-control::placeholder {
  color: #ffffff50 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}