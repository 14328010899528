.payment-container {
  text-align: center;
}

.payment-methods {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.payment-method {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.payment-method img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.payment-method span {
  margin-top: 10px;
}
