.chip {
  background-color: rgba(255, 255, 255, 0.18);
  border: none;
  border-radius: 10px;
}

.chip > div {
  border: none;
  border-radius: 10px;
  padding-bottom: 4px !important;
  flex-direction: row !important;
  min-height: 7vh;
}

.chip > div > fieldset {
  border: none;
}

.chip > div:focus-within {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.chip > div:hover {
  transition: all 0.1s ease-in-out;
}

.chip > div > input:focus,
.chip > div:focus {
  outline: none;
}

.chip > div > input::placeholder {
  color: #d7d7d7;
  font-size: 1rem;
}
