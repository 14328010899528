/* Styled Main Panel */
.containerMain {
  justify-content: center;
  flex-direction: column;
  margin: 0%;
  padding: 5%;
}
.specificInput {
  background-color: rgba(255, 255, 255, 0.018);
}
.container-components {
  display: flex;
}

/* Style Navbar */
.container-navbar {
  /* display: flex; */
}

/* Contain the text <h2> && <h5> */
.container-text {
  text-align: start;
  color: #ffffff;
}

/* New Styles with classes of Boostrap */
.container {
  color: #ffffff;
}
.row {
  color: #ffffff;
}

/* Style of General---Color */
.label-color {
  background-color: "rgba(255,255,255,0.2)";
  height: "50px";
  width: "100px";
  border-radius: "10px";
  color: "white";
}

hr {
  height: 1px;
  color: #fff;
  background-color: #fff;
  outline: solid 1 #fff;
}
.line {
  border-top: 1px solid rgb(255, 255, 255);
  height: 2px;
  max-width: 100%;
  padding: 0;
  margin: auto;
}

/* input style */
#inputss {
  height: 7vh;
  background-color: transparent;
  color: #ffffff;
  border-radius: 10px;
}

#inputss::placeholder {
  color: #ccc;

  padding-left: 10px;

  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
/* Inputs placeholder--White */
#inputss-white {
  height: 7vh;
  background-color: transparent;
  color: #ffffff;
  border-radius: 10px;
}

#inputss-white::placeholder {
  color: #fff;

  padding-left: 10px;

  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.Input {
  color: red;
}


#inputs-center {
  height: 7vh;
  background-color: transparent;
  color: #ffffff;
  border-radius: 10px;
  text-align: center;
}
#inputs-center::placeholder {
  color: #ccc;

  padding-left: 10px;

  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.inputclass {
  height: 7vh;
  background-color: transparent;
}

.inputclass::placeholder {
  color: #ffffff;
}

#style-category-preview {
  background-color: rgba(255, 255, 255, 0.149);
  border-radius: 20px;
  padding-left: 16px;
  width: 95%;
  height: 302px;
}

.select-style {
  background-color: transparent;
  border: none;
}

/* Style of Texts */
#text-light {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

#big-title {
  font-family: "Inter", sans-serif;
  font-weight: SemiBold 600;
  font-size: 2rem;
}
#title {
  font-family: "Inter", sans-serif;
  font-weight: SemiBold 600;
  font-size: 2rem;
}
#midle-title {
  font-family: "Inter", sans-serif;
  font-weight: SemiBold 600;
  font-size: 1.5rem;
}
#subtile-font {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
#subtitle {
  font-family: "Inter", sans-serif;
  font-weight: Medium 500;
}

#padding-left {
  padding: 10px;

  font-family: "Inter", sans-serif;
  font-weight: Medium 500;
  font-size: 1rem;

  color: #fff;
}
#normal {
  font-family: "Inter", sans-serif;
  font-weight: Medium 500;
  font-size: 1rem;
}

/* Style of box from "Crea tu Espacio!" */
.box-container {
  border: 1px solid white;
  color: #ffffff;
  background: transparent;
  border-radius: 25px;
  margin: 0;
  padding: 12px;
  text-align: start;
  width: 100%;
  padding-left: 10px;
}
.box-container > div > button {
  display: block;
  align-content: center;
  margin-top: 30px;
}

/*Style General--Links*/
.container-general-links {
  display: grid;
  width: 50vw;
}
.container-general-links .social-media {
  display: flex;
  flex-direction: column;
  width: 40vw;
  min-width: 20rem;
  max-width: 40rem;
}
.container-general-links .social-media > div {
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
}
.container-general-links .social-icons {
  margin-right: 20px;
  height: 40px;
}

/* Style of SWITCH */
.form-switch .form-check-input {
  background-color: transparent;
  background-image: url(./assets/blancosvg.svg) !important;
  border: solid 1px #ffffcc;
}
