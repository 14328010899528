body {
  background: linear-gradient(90deg, #f247ff 0%, #079fff 100%);
  width: 100vw;
  margin: 0;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overflow-auto::-webkit-scrollbar {
  width: 0.15rem;
  height: 0px;
}

/* [data-active*="active"] {
	background-color: rgb(73, 180, 237);
} */

.overflow-auto::-webkit-scrollbar-track {
  background-color: transparent;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
