@keyframes shake {
  0%, 100% {
    transform: rotate(0deg); /* Sin rotación */
  }
  25% {
    transform: rotate(1deg); /* Girar hacia la derecha */
  }
  75% {
    transform: rotate(-1deg); /* Girar hacia la izquierda */
  }
}
