svg {
  margin: auto;
}

.table-row {
  background: none !important;
  border-radius: 10px !important;
  border: 1px solid #D9D9D9 !important;
}

.container {
  margin: auto;
}
