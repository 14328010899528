input[type="color"]::-moz-color-swatch {
    border-radius: 10px;
  }
  
  input[type="color"]::-ms-color-swatch {
    border-radius: 10px;
  }
  
  input[type="color"]::-webkit-color-swatch {
    border-radius: 10px;
  }